<template>
  <Results />
</template>

<script>
import Results from '@/components/Results.vue'

export default {
  name: 'ResultsView',
  components: {
    Results
  }
}
</script>
