<template>
  <HowToAnalyzeITOL />
</template>

<script>
import HowToAnalyzeITOL from '@/components/HowToAnalyzeITOL.vue'

export default {
  name: 'AnalyzeView',
  components: {
    HowToAnalyzeITOL
  }
}
</script>

<style>

</style>
