<template>
  <Terms />
</template>

<script>
import Terms from '@/components/Terms.vue'

export default {
  name: 'TermsView',
  components: {
    Terms
  }
}
</script>
