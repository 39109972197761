<template>
  <DatabaseDetails />
</template>

<script>
// @ is an alias to /src
import DatabaseDetails from '@/components/DatabaseDetails.vue'

export default {
  name: 'DatabaseView',
  components: {
    DatabaseDetails
  }
}
</script>
