<template>
  <GettingStarted />
</template>

<script>
import GettingStarted from '@/components/GettingStarted.vue'

export default {
  name: 'GettingStartedView',
  components: {
    GettingStarted
  }
}
</script>

<style>

</style>
