<template>
  <Help />
</template>

<script>
// @ is an alias to /src
import Help from '@/components/Help.vue'

export default {
  name: 'HelpView',
  components: {
    Help
  }
}
</script>
