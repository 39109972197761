/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:a731f353-6319-4763-871d-a4d28f9ff144",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_hHojHj5dp",
    "aws_user_pools_web_client_id": "7ko8cp7e35fv6f9ocv3ktpojat",
    "oauth": {},
    "aws_user_files_s3_bucket": "cydrasil-web-app0638c0bca402486580c27990f5dfb57app-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://qbc6pnjfqrgi3pz5ffb2qbgnpy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
