<template>
  <ProcessingPlacements />
</template>

<script>

// @ is an alias to /src
import ProcessingPlacements from '@/components/ProcessingPlacements.vue'

export default {
  name: 'ProcessingPlacementsView',
  components: {
    ProcessingPlacements
  }
}
</script>

<style>

</style>
