<template>
  <MyPlacementsList />
</template>

<script>

// @ is an alias to /src
import MyPlacementsList from '@/components/MyPlacementsList.vue'

export default {
  name: 'MyPlacementsListView',
  components: {
    MyPlacementsList
  }
}
</script>

<style>

</style>
