<template>
  <PlacementForm />
</template>

<script>
// @ is an alias to /src
import PlacementForm from '@/components/PlacementForm.vue'

export default {
  name: 'PlacementFormView',
  components: {
    PlacementForm
  }
}
</script>
