<template>
  <ErrorPage />
</template>

<script>
// @ is an alias to /src
import ErrorPage from '@/components/ErrorPage.vue'

export default {
  name: 'ErrorView',
  components: {
    ErrorPage
  }
}
</script>
