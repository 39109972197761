<template>
  <AboutAndContact />
</template>

<script>
// @ is an alias to /src
import AboutAndContact from '@/components/AboutAndContact.vue'

export default {
  name: 'AboutAndContactView',
  components: {
    AboutAndContact
  }
}
</script>
